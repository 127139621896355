/**
 * Description
 *
 * @package     staffsc.vcom
 * @category    Source
 * @date        2018-12-07
 */


section#home {
  margin: 0;
  background-color: $default-color;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  color: $default-text-color;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

}

section#homeContent {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  color: $default-text-color;

  background: radial-gradient(circle at center, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.2) 100%);
}
/*

a.whistle-button {
  //font-size: 0.7rem;
  color: $whistle-color;
  text-decoration: none;

  padding: 7px 12px;
  margin-right: 15px;
  background: #d8e4f1;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;

  &:last-of-type {
    margin-right: 0;
  }

  div {
    display: flex;
    align-content: center;
    align-items: center;
  }
}
*/


#callToActionBtn,
.whistle-button,
.helpdesk {
  position: fixed;
}

#callToActionBtn, .whistle-button {
  right: 3rem;
}

#callToActionBtn {
  bottom: 80px;
  background-color: $default-btn-color;
  color: #ffffff;
}

.whistle-button, .helpdesk {
  bottom: 30px;
}

.whistle-button {
  font-size: 0.7rem;
  color: $whistle-color;
  background-color: rgba(255, 255, 255, 0.7);

  a {
    text-decoration: none;
  }
}


.stressed-btn {
  font-size: 1.2rem;
}

.stressed-btn-fw {
}

.stressed-btn-fh {
}

.helpdesk {
  position: fixed;
  left: 100px;
  bottom: 30px;
}

@media (max-width: 767.98px) { // Mobile
  .company-logo {
    width: 100px;

    font-size: 1.5rem;
    font-weight: bold;
  }

  .company-hrms-logo {
    width: 70px;
  }

  .company-hrms-logo, .company-logo {
    img {
      width: 100%;
    }
  }

  #closeButtonMobile {
    cursor: pointer;
  }

  #callToActionBtnMobile,
    //.whistle-button,
  .helpdesk {
    position: fixed;
  }

  #callToActionBtnMobile
    //, .whistle-button
  {
    left: 50%;
    transform: translateX(-50%) !important;
  }

  #callToActionBtnMobile {
    bottom: 80px;
    background-color: $default-btn-color !important;
    color: #fff;
  }

  //.whistle-button,
  .helpdesk {
    bottom: 30px;
  }

}

@media (min-width: 768px) { // Desktop

  .company-logo {
    position: fixed;
    left: 3rem;
    top: 2.3rem;
    font-size: 2.5rem;
    font-weight: bold;
  }

  #closeButton {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  #callToActionBtn,
    //.whistle-button,
  .helpdesk {
    position: fixed;
  }

  #callToActionBtn, .whistle-button {
    right: 3rem;
  }

  #callToActionBtn {
    bottom: 80px;
    background-color: $default-btn-color;
    color: #fff;
  }

  //.whistle-button,
  .helpdesk {
    bottom: 30px;
  }

}

#loginFormMobile {
  display: none;

  #loginAuthForm {
    .form-group {
      span#eye-icon {
        display: none;
      }
    }
  }
}

#loginForm {
  display: none;

  position: fixed;
  right: 5.8rem;
  top: 5rem;

  color: #505050;

  width: 450px;
  max-height: 80vh;

  background-color: rgba(255, 255, 255, 0.95);
  padding: {
    top: 30px;
    bottom: 30px;

    left: 35px;
    right: 35px;
  }

  div.form-wrapper {
    padding: {
      top: 20px;
      bottom: 20px;
    }

    height: 45vh;

    @extend .noscrollbar-scrollable-y-section;

    .form-group {
      margin-bottom: 30px;
      position: relative;

      span#eye-icon {
        position: absolute;
        right: 10px;
        top: 40px;
        color: #868686;
        cursor: pointer;
      }
    }
  }

  header.login-form {
    margin: {
      top: 25px;
      bottom: 25px;
    }

    h1 {
      font-weight: bold;
      font-size: 2.5rem;
    }
  }

}

#homeContentMobile {
  padding: 0;
  margin: 0;

  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

#homeContentMobile, #homeContent {

  input.form-control {
    border-style: none none solid none;
    border-color: #777;
    color: #888;
    border-radius: 0;
    background-color: inherit;
    height: 40px;
    padding: {
      left: 0;
      right: 0;
    }

    &::placeholder {
      color: #888;
    }

    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

}

.company-hrms {
  position: fixed;
  right: 2.5rem;
  top: 2rem;
  z-index: 9;
}

.welcome-heading {
  .welcome-prefix {
    letter-spacing: 0.27rem;
    text-transform: uppercase;
  }
}

.noscrollbar-scrollable-y-section {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; /* make scrollbar transparent */
    -ms-overflow-style: none;
    display: none;
    overflow: -moz-scrollbars-none;
    -webkit-appearance: none;
    z-index: -99;
  }
}

//.sign-in-btn,
.form-label {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.sign-in-btn {
  background-color: $default-btn-color;
  color: white;
  margin: {
    top: 35px;
    bottom: 20px;
  }
  padding: 14px 60px;
  font-size: 16px;
}

.backstretch {
  background-color: $default-color;
}

textarea {
  resize: none;
}

.landing {
  height: 100vh;
  background-color: #000;
}

.modal {
  background: rgba(0, 0, 0, 0.6)
}

.modal-backdrop {
  background: none;
}

.company {
  width: 75px;
  height: 75px;

  margin-right: 20px;
}

.company__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.forgot__password {

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #454545;
}

.forgot__message {
  font-size: 15px;
  color: #777;
  margin-bottom: 50px;
}

.authentication__sign {
  font-weight: 500;
  font-size: 20px;
  color: #454545;
  margin-bottom: 30px;
}

.landing__span {
  margin-top: -0.5rem;

  margin-bottom: 2rem;

  font-size: 13px;
  line-height: 19px;
  color: #777;
}

.landing__site {

  margin-bottom: 2rem;

  font-size: 13px;
  line-height: 19px;
  color: #777;
}

.landing__action {
  //position: absolute;
  //bottom: 1rem;
  margin-bottom: 30px;
}

.landing__company {
  font-weight: 500;
  font-size: 18px;
  color: #454545;
  word-wrap: break-word;
  width: 180px;
}

#authentication__slider {
  background: #fff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  width: 490px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: none;
}

.landing__header {


  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
}

.landing__text {

  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  color: #fff;
}

.landing__info {

  font-size: 24px;
  //width: 39.8%;
  color: #fff;
}

@media (max-width: 768px) {
  .landing__info {
    width: 100%;
  }
}

.company-info {
  margin-bottom: 45px;
}

.social-logins {
  margin-bottom: 40px;
}

.social-login {
  width: 56px;
  height: 56px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.native-login-form {
  //margin-bottom: ;
}

.native-login-form-submit-button {
  margin-top: 62px;
}

.footer-right-reserve {
  font-size: 13px;
  letter-spacing: 1px;

  /* Dark/LT1 */
  color: #aaa;
}

.remember-me-label {
  color: #777;
  line-height: 9px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.home-page-footer {
  margin-bottom: 62px;
}

.home-page-footer-links {
  margin: 0;

  a {
    letter-spacing: 1px;
    margin: {
      right: 20px;
    }
  }
}

.login-section {
  overflow-y: auto;
}

.native-forgot-password-form {
  margin-bottom: 45px;
}

.modal-backdrop {
  display: none;
}