// Rubik : Formally Muli
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,700,700i');
// Kristi
@import url('https://fonts.googleapis.com/css?family=Kristi');

html, body {
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  color: #454545;
  font-size: 15px;
}

.font-kristi {
  font-family: 'Kristi', cursive;
}

.font-size-52 {
  font-size: 55px; // compensated it with 3px
}

$default-text-color: #fff;
$default-color: #2889ce;
//$default-btn-color: #8B2B2A;
$default-btn-color: $default-color;
$whistle-color: #296db3; // old value: darken(#C77373, 20%);
$darker-default-color: darken($default-color, 20%);

@import 'seamlesshrms';